import React from "react"
import Squeeze from "../layouts/squeeze-landing"
import styles from "./buy.module.css"
import Base from "../layouts/base"
import SEO from "../components/SEO"

export default () => (
  <div>
    <SEO
      title="Where to Buy Panls Whiteboards | Magnetic Dry Erase Boards"
      description="This is the description"
    />
    <Base>
      <Squeeze>
        <div className="row my-3 text-center">
          <div className="twelve columns">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-buy.jpg"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row mt-3 px-1 text-center">
          <div className="twelve columns">
            <h1 className="mb-1">How to Buy Panls</h1>
            <p className={styles.dealerParagraph}>
              Panls whiteboards are available through our extensive network of
              national and international distribution partners. We only
              distribute our dry erase boards through reputable outlets which
              have been verified by our team.
            </p>
          </div>
        </div>

        <div className={`${styles.vCenter} row my-2 py-3 text-center`}>
          <div className="four columns">
            <a
              className={styles.dealerLink}
              href="https://www.touchboards.com/panls/whiteboards/"
              target="blank"
            >
              <img
                src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/TB-274px-wide.png"
                className={`${styles.dealerImg} img-fluid`}
              />
            </a>
          </div>
          <div className="four columns">
            <a
              className={styles.dealerLink}
              href="https://www.tequipment.net/Panls/whiteboards/"
              target="blank"
            >
              <img
                src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/te-logo-slim.png"
                className={`${styles.dealerImg} img-fluid`}
              />
            </a>
          </div>
          <div className="four columns">
            <a
              className={styles.dealerLink}
              href="https://www.techedu.com/Panls/dry-erase-chalk-and-tack-boards/#/panls/dry-erase-chalk-and-tack-boards/white-boards/"
              target="blank"
            >
              <img
                src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/TD-274px-wide.png"
                className={`${styles.dealerImg} img-fluid`}
              />
            </a>
          </div>
        </div>

        <div className="row my-3 px-2 py-2 bg-light-grey border">
          <div className="twelve columns">
            <h2 className="div-heading">About our trusted partners</h2>
            <p className="mb-1">
              We distribute Panls whiteboards only through our approved
              authorized distribution partners. All our partners strive to
              uphold a level of care and quality in order to make buying smooth
              and easy for you, the customer. Whether you need a school
              whiteboard or business whiteboard, our partners will get you the
              best price on the right product for your needs.
            </p>
            <p>
              All of our distribution partners are well-versed in dry erase
              board applications and will be able to guide you through the
              purchase process so you make the right buying decision. Make sure
              to only purchase Panls dry erase boards through the authorized
              distributors listed on this page – this is the only way to ensure
              you receive full product support and warranty service after
              purchasing your whiteboard. If you ever have an issue with your
              Panls whiteboard or need warranty service, contact the authorized
              distributor you purchased from and they will connect you to the
              proper support channels.
            </p>
          </div>
        </div>
      </Squeeze>
    </Base>
  </div>
)
